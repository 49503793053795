import React from 'react';

import './styles.css';

function NotFound() {
  return (
    <div id="notfound">
      <h1>Page Not Found</h1>
    </div>
  );
}

export default NotFound;
