import React from 'react';
import './style.css';
import logo from '../../images/splashScreenLogo.png';

function SplashScreen() {
  return (
    <div className="splash-screen">
      <img src={logo} alt='logo' />
      <div className="splashSpinner"></div>
    </div>
  );
}

export default SplashScreen;
