import React from 'react';
import { useState } from 'react';
import './style.css';
import '../../global.css'
import {Link, useNavigate} from 'react-router-dom';
import api from '../../services/api';
import logo from '../../images/vitrinneLogoAlternative.png';

export default function Login() {
  const[login, setLogin] = useState('');
  const[senha, setSenha] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  async function makeLogin(e){
    e.preventDefault();

    const data = { login, senha };

    try {
        const response = await api.post('/admin/login', data);
        const token = response.data.token;
        
        localStorage.setItem('authToken', token);

        navigate('/dashboard');
    }
    catch(err) {
        alert(`Erro: ${err.response?.data?.message || 'Ocorreu um erro inesperado.'}`);
        console.log(err);
    }   
  }

  return (
    <div className="main" id='login-main'>
        <div className='login-main'>
            <Link className='close' to={'/'}>
                <span className="material-symbols-outlined notranslate">&#xe5cd;</span>
            </Link>
            <div className='login'>
                <img src={logo} alt='logo'/>

                <form onSubmit={makeLogin}>
                <h1>Painel do administrador</h1>
                <label>Login</label>
                <div className='input-div'>
                    <span className="material-symbols-outlined notranslate">&#xe873;</span>           
                    <input required  type="text" value={login} onChange={e => setLogin(e.target.value)} placeholder="Digite seu login"/><br/>
                </div>

                <label>Senha</label>
                <div className='input-div'>
                    <span className="material-symbols-outlined notranslate">&#xe897;</span>
                    <input required id="password"  type={showPassword ? "text" : "password"} value={senha} onChange={e => setSenha(e.target.value)} placeholder="Digite sua senha"/><br/>
                    <span className="material-symbols-outlined notranslate" onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>{showPassword ? String.fromCharCode(0xe8f5) : String.fromCharCode(0xe8f4)}</span>
                </div>
                <button className='acess-button' type="submit">Acessar</button>
                </form>
            </div>  
        </div>
    </div>
  ); 
}
