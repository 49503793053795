import React from 'react';
import { useState, useEffect} from 'react';
import './style.css';
import '../../global.css'
import {Link, useNavigate, useLocation } from 'react-router-dom';
import api from '../../services/api';
import InputMask from 'react-input-mask';
import ImageCropper from '../../componnents/ImageCropper';
import municipios from './municipiosBrasil.json';

export default function Editar() {
    const location = useLocation();
    const { comerciante } = location.state || {};

    const [cpfCnpj, setCpfCnpj] = useState('');
    const [nome, setNome] = useState('');
    const [cidade, setCidade] = useState('');
    const [contato, setContato] = useState('');
    const [instagram, setInstagram] = useState('');
    const [tipo, setTipo] = useState('');
    const [servico, setServico] = useState('');
    const [descricao, setDescricao] = useState('');
    const [tipoContrato, setTipoContrato] = useState('');
    const [contrato, setContrato] = useState('');
    const [contratoDateInput, setContratoDateInput] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [comercianteImages, setComercianteImages] = useState([]);
    const [cidadesFiltradas, setCidadesFiltradas] = useState([]);
    const [cidadeSelecionada, setCidadeSelecionada] = useState('');
    const [mask, setMask] = useState('99.999.999/9999-99');
    const [comercianteImagesPreview, setComercianteImagesPreview] = useState([]);
    const [croppedImage, setCroppedImage] = useState([]);
    const [isCropperOpen, setIsCropperOpen] = useState(false);
    const navigate = useNavigate();

    const openCropper = () => {
        setIsCropperOpen(true);
    };

    const handleComercianteImagesChange = (e) => {
        const files = Array.from(e.target.files); 
        setComercianteImages((prevImages) => [...prevImages, ...files]);
        const previewUrls = files.map((file) => URL.createObjectURL(file));
        setComercianteImagesPreview((prevPreviews) => [...prevPreviews, ...previewUrls]);
    };

    const removeImage = (index) => {
        setComercianteImages((prevImages) => prevImages.filter((_, i) => i !== index));
        setComercianteImagesPreview((prevPreviews) => prevPreviews.filter((_, i) => i !== index));
    };

    const handleCrop = (file) => {
        setProfileImage(file);
        const imageUrl = URL.createObjectURL(file);
        setCroppedImage(imageUrl)
    };

    const handleClose = () => {
        setIsCropperOpen(false);
    };

    const handleCpfCnpjChange = (e) => {
        setCpfCnpj(e.target.value.replace(/\D/g, ''));
    };

    const apenasNumeros = (str) => str.replace(/\D/g, '');

    const handleBlurCity = () => {
        if (!cidadeSelecionada) {
          setCidade(''); // Limpa se nada foi selecionado
        }
        setCidadeSelecionada('');
    };

    const handleBlur = () => {
        if (cpfCnpj.length === 11) {
            setMask('999.999.999-99');
        } else {
            setMask('99.999.999/9999-99');
        }
    };

    const handleComercianteImageClick = () => {
        document.getElementById("file-comercianteImages").click();  // Abre o seletor de arquivos
    };

    const handleFocus = () => {
        setMask('99.999.999/9999-99');
    };

    const handleCidadeChange = (e) => {
        const inputValue = e.target.value;
        setCidade(inputValue);
        const filteredCities = municipios.cities.filter(city => 
            city.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setCidadesFiltradas(filteredCities);
    };

    const handleCidadeSelect = (selectedCity) => {
        const cidadeFormatada = `${selectedCity.name} - ${estados[selectedCity.state_id]}`;
        setCidade(cidadeFormatada);
        setCidadeSelecionada(cidadeFormatada);
        setCidadesFiltradas([]);
    };

    const servicosOpcoes = {
        'comércio': [
            'Açougue', 'Agropecuária', 'Armarinho', 'Autopeças', 
            'Alimentos', 'Animais', 'Bijuterias', 'Bazar', 'Bicicletaria', 'Brechó',
            'Brinquedos', 'Calçados', 'Casa de material de construção', 'Casa de festas', 'Casa de tecidos',
            'Construção', 'Cosméticos', 'Decoração', 'Distribuidora de bebidas', 'Doceria',
            'Eletrodomésticos', 'Eletrônicos', 'Enxoval para bebês', 'Esportes', 'Farmácia', 
            'Ferragens', 'Floricultura', 'Fotografia', 'Funilaria', 'Gráfica', 'Gelateria', 'Games',
            'Hortifrúti', 'Hotel', 'Hotel para pets', 'Informática', 'Instrumentos musicais', 'Iluminação',
            'Joalheria', 'Jardinagem', 'Livraria', 'Loja de departamentos', 'Loja de fantasias', 'Loja de móveis',
            'Móveis', 'Marcenaria', 'Material escolar', 'Mercado', 'Moda praia', 'Música',
            'Nutrição e suplementos', 'Ótica', 'Oficina de costura', 'Papelaria', 'Pet shop', 'Perfumes', 
            'Presentes', 'Queijaria', 'Relojoaria', 'Rouparia', 'Roupas', 'Roupas de cama, mesa e banho',
            'Sapateria', 'Sorveteria', 'Suplementos', 'Suplementos alimentares', 'Sex shop', 'Tabacaria',
            'Tatuagem e piercing', 'Tecidos', 'Turismo', 'Utilidades domésticas', 'Uniformes', 
            'Vidraçaria', 'Variedades', 'Venda de automóveis'
        ],
        'prestador de serviço': [
            'Advogado', 'Agente de viagens', 'Assistência técnica', 'Arquiteto', 
            'Babá', 'Buffet para eventos', 'Borracheiro', 'Barbeiro',
            'Cabeleireiro', 'Carpinteiro', 'Contador', 'Cuidador de idosos',
            'Designer gráfico', 'Diarista', 'Dedetizador', 'DJ',
            'Eletricista', 'Encanador', 'Entregador', 'Esteticista',
            'Faxineira', 'Fisioterapeuta', 'Fotógrafo', 'Funileiro',
            'Guia turístico', 'Gestor de redes sociais',
            'Hidráulico', 'Hospedagem de sites',
            'Instrutor de yoga',
            'Jardineiro', 'Jornalista freelance',
            'Lavador de carros', 'Limpeza de piscina', 'Locutor',
            'Manicure e pedicure', 'Mecânico', 'Montador de móveis', 'Motorista particular',
            'Nutricionista',
            'Organização de festas', 'Ortopedista',
            'Pedreiro', 'Personal trainer', 'Pintor', 'Psicólogo', 'Professor particular',
            'Quiropraxista',
            'Refrigeração e ar-condicionado',
            'Segurança', 'Serralheiro',
            'Técnico de informática', 'Terapeuta',
            'Veterinário', 'Vidraceiro',
            'Zelador'
        ],
        imóvel: ['Construtora', 'Imobiliária', 'Lote']
    };

    const handleTipoChange = (e) => {
        setTipo(e.target.value);
        setServico('');
    };
  
    const estados = municipios.states;

    useEffect(() => {
        if (comerciante) {
            setCpfCnpj(comerciante.cpfCnpj || '');
            setNome(comerciante.nome || '');
            setCidade(comerciante.cidade || '');
            setContato(comerciante.contato || '');
            setInstagram(comerciante.instagram || '');
            setTipo(comerciante.tipo || '');
            setServico(comerciante.servico || '');
            setDescricao(comerciante.descricao || '');
            setTipoContrato(comerciante.tipoContrato || '');
            setContrato(comerciante.contrato || '');
            setProfileImage(comerciante.profileImage || null);
            const images = comerciante.comercianteImages || [];
            setComercianteImages(images);
            setComercianteImagesPreview(images);
            setCroppedImage(comerciante.profileImage);
            setContratoDateInput(formatDateForInput(comerciante.contrato));
        }
    }, [comerciante]);

    const formatDateForInput = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
      };

    async function editarComerciante(e) {
        e.preventDefault();
        
        const formData = new FormData();
    
        formData.append('cpfCnpj', apenasNumeros(cpfCnpj));
        formData.append('nome', nome);
        formData.append('cidade', cidade);
        formData.append('contato', apenasNumeros(contato));
        formData.append('instagram', instagram);
        formData.append('tipo', tipo);
        formData.append('servico', servico);
        formData.append('descricao', descricao);
        formData.append('tipoContrato', tipoContrato);
        formData.append('contrato', contrato);
        
        if (profileImage) {
            formData.append('profileImage', profileImage);
        }
    
        comercianteImages.forEach((image) => {
            formData.append('comercianteImages', image);
        });
    
        try {
            const idComerciante = comerciante.id;
            await api.put(`comerciantes/updateComerciante/${idComerciante}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            
            alert('Comerciante atualizado com sucesso!');
            navigate('/dashboard');
        } catch (err) {
            alert('Erro ao atualizar comerciante!');
            console.log(err);
        }
    }
    

    return (
        <div className="main" id='cadastro-main'>
            <div className='cadastro-main'>
                <Link className='close' to={'/dashboard'}>
                    <span className="material-symbols-outlined notranslate">&#xe5cd;</span>
                </Link>
                <div className='cadastro'>
                    <form onSubmit={ editarComerciante }>
                        <h1>Editar dados do comerciante</h1>
                        <div className='cadastro-form'>
                        <div className='label-input'>
                                <label>Tipo</label>
                                <div className='input-div'>
                                    <span class="material-symbols-outlined notranslate">{tipo === 'comércio' ? String.fromCharCode(0xe8d1) : tipo === 'prestador de serviço' ? String.fromCharCode(0xea3c) : tipo === 'imóvel' ? String.fromCharCode(0xe73a) : ''}</span>
                                    <select value={tipo} onChange={handleTipoChange} required>
                                        <option value="" disabled>Selecione o tipo de comerciante</option>
                                        <option value="comércio">Comércio</option>
                                        <option value="prestador de serviço">Prestador de Serviço</option>
                                        <option value="imóvel">Imóvel</option>
                                    </select>
                                    <span class="material-symbols-outlined notranslate">&#xe313;</span>
                                </div>
                            </div>
                            <div className='label-input'>
                                <label>Serviço</label>
                                <div className='input-div'>
                                    <select value={servico} onChange={(e) => setServico(e.target.value)} required>
                                        <option value="" disabled>Selecione o serviço</option>
                                        {servicosOpcoes[tipo]?.map((opcao, index) => (
                                        <option key={index} value={opcao}>{opcao}</option>
                                        ))}
                                    </select>
                                    <span class="material-symbols-outlined notranslate">&#xe313;</span>
                                </div>
                            </div>
                            <div className='label-input'>
                                <label>CPF/CNPJ</label>
                                <div className='input-div'>
                                    <span class="material-symbols-outlined notranslate">&#xe873;</span>
                                    <InputMask
                                        mask={mask}
                                        maskChar={null}
                                        value={cpfCnpj}
                                        onChange={handleCpfCnpjChange}
                                        onBlur={handleBlur}
                                        onFocus={handleFocus}
                                        placeholder="Digite o CPF ou CNPJ"
                                        required
                                    >
                                        {(inputProps) => (
                                            <input
                                            {...inputProps}
                                            type="text"
                                            />
                                        )}
                                    </InputMask>
                                </div>
                            </div>
                            <div className='label-input'>
                                <label>Nome</label>
                                <div className='input-div'>
                                    <span class="material-symbols-outlined notranslate">&#xe7fd;</span>
                                    <input
                                        type="text"
                                        value={nome}
                                        onChange={(e) => setNome(e.target.value)}
                                        placeholder="Nome do Comerciante"
                                        required
                                    />
                                </div>
                            </div>
                            <div className='label-input'>
                                <label>Cidade</label>
                                <div className='input-div'>
                                    <span class="material-symbols-outlined notranslate">&#xe0c8;</span>
                                    <input
                                        type="text"
                                        value={cidade}
                                        onChange={handleCidadeChange}
                                        onBlur={handleBlurCity}
                                        placeholder="Digite a cidade"
                                        required
                                    />
                                    </div>
                                    {cidadesFiltradas.length > 0 && (
                                        <ul className="cidades-list">
                                            {cidadesFiltradas.map(city => (
                                                <li key={city.id} onClick={() => handleCidadeSelect(city)}>
                                                    {city.name} - {estados[city.state_id]}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                            </div>    
                            <div className='label-input'>
                                <label>Contato</label>
                                <div className='input-div'>
                                    <span class="material-symbols-outlined notranslate">&#xe0b0;</span>
                                    <InputMask required value={contato} mask="(99) 9 9999-9999" onChange={e => setContato(e.target.value)} placeholder="Digite o telefone"/>
                                </div>
                            </div>
                            <div className='label-input' id='descricao'>
                                <label>Descrição</label>
                                <div className='input-div'>
                                    <textarea
                                        value={descricao}
                                        onChange={(e) => setDescricao(e.target.value)}
                                        placeholder="Descrição das habilidades e serviços oferecidos pelo comerciante"
                                    />
                                </div>
                            </div>
                            <div className='label-input'>
                                <label>Instagram</label>
                                <div className='input-div'>
                                    <span class="material-symbols-outlined notranslate">&#xe0e6;</span>
                                    <input
                                        type="text"
                                        value={instagram}
                                        onChange={(e) => setInstagram(e.target.value)}
                                        placeholder="@ do instagram"
                                    />
                                </div>
                            </div>
                            <div className='label-input'>
                                <label>Tipo de Contrato</label>
                                <div className='input-div'>
                                    <span class="material-symbols-outlined notranslate">&#xf5a0;</span>
                                    <select value={tipoContrato} onChange={(e) => setTipoContrato(e.target.value)} required>
                                        <option value="" disabled>Selecione o tipo de contrato</option>
                                        <option value="normal">Normal</option>
                                        <option value="destaque">Destaque</option>
                                    </select>
                                </div>
                            </div>
                            <div className='label-input'>
                                <label>Data de Validade do Contrato</label>
                                <div className='input-div'>
                                    <input
                                        type="date"
                                        value={contratoDateInput}
                                        onChange={(e) => setContrato(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='label-input'>
                                <label>Foto de Perfil</label>
                                <div className='input-div'>
                                    <div className='images-div'>
                                      {profileImage  && (
                                        <img className="cropped-image-preview" src={croppedImage} alt="Imagem recortada" />
                                      )}
                                      <input type="button" onClick={openCropper} className='add-img-button' value="Selecionar e cortar imagem" required />
                                    </div>
    
                                    <ImageCropper
                                      isOpen={isCropperOpen}
                                      onClose={handleClose}
                                      onCrop={handleCrop}
                                    />
                                </div>
                            </div>
                            <div className='label-input' id='comercianteImages'>
                                <label>Imagens do Comerciante (até 4)</label>
                                <div className='input-div'>
                                    <div className="comercianteImages-div">
                                      {comercianteImagesPreview.map((imageUrl, index) => (
                                        <div key={index} className="image-preview-container">
                                          <img className="cropped-image-preview" src={imageUrl} alt={`Imagem ${index + 1}`} />
                                          <button required type="button" className='add-img-button' style={{ marginLeft:"5%", cursor: "pointer" }} onClick={() => removeImage(index)}>Remover</button>
                                        </div>
                                      ))}
                                      {comercianteImagesPreview.length < 4 && (
                                        <div>
                                          <input type="file" accept="image/*" multiple id='file-comercianteImages' onChange={handleComercianteImagesChange} style={{ display: "none" }} required />
                                          <button type="button" className='add-img-button' onClick={handleComercianteImageClick} style={{ marginLeft:"5%", cursor: "pointer" }}>
                                              Adicionar imagem
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                </div>
                            </div>
                            
                            <button className='acess-button' type="submit">Editar dados</button>
                        </div>
                    </form>
                </div>  
            </div>
        </div>
      ); 
    }
    